<template>
    <div class="page-content">
        <div class="grid" v-if="loading">
            <div class="col-6 md:col-3" v-for="n in 8" :key="n">
                <Skeleton shape="rectangle" width="100%" height="150px" />
            </div>
        </div>
        <template v-if="!loading && homePage">
            <div class="grid">
                <template v-for="(col, i1) in homePage.content.layout[0].children" :key="i1">
                    <div :class="col.class">
                        <template v-for="(el1, i2) in col.children" :key="i2">
                            <h3 v-if="el1.type == 'header-3'" :class="el1.class">{{ el1.title }}</h3>
                            <div v-else-if="el1.type == 'grid'" :class="el1.class">
                                <div v-for="(el2, i3) in el1.children" :key="i3" :class="el2.class">
                                    <template v-for="(el3, i4) in el2.children" :key="i4">
                                        <h3 v-if="el3.type == 'header-3'" :class="el3.class">{{ el3.title }}</h3>
                                        <router-link v-else-if="el3.type == 'category-button'" :class="`column-button category-button items-start ${$appState.estate.extra_data.button_type}`" :to="`/categories/${el3.data.slug}`">
                                            <img v-if="el3.data.extra_data?.icon_type === 'icon' && el3.data.extra_data?.icon" :src="`https://api.iconify.design/${iconUri(el3.data.extra_data?.icon, 0)}/${iconUri(el3.data.extra_data?.icon, 1)}.svg?color=%23${el3.data.extra_data.icon_color ?? '6366F1'}`" alt="">
                                            <img v-if="el3.data.extra_data?.icon_type === 'image' && el3.data.extra_data?.icon" :src="getIconImageUrl(el3.data.id)" alt="">
                                            <div class="button-wrapper">
                                                <h5>{{ el3.data.name }}</h5>
                                                <h6 v-if="el3.data.short_desc">{{ el3.data.short_desc }}</h6>
                                            </div>
                                        </router-link>
                                        <router-link v-else-if="el3.type == 'page-button'" :class="`column-button page-button items-start ${$appState.estate.extra_data.button_type}`" :to="`/pages/${el3.data.slug}`">
                                            <img v-if="el3.data.content?.icon_type === 'icon' && el3.data.content?.icon" :src="`https://api.iconify.design/${iconUri(el3.data.content?.icon, 0)}/${iconUri(el3.data.content?.icon, 1)}.svg?color=%23${el3.data.content.icon_color ?? '6366F1'}`" alt="">
                                            <img v-if="el3.data.content?.icon_type === 'image' && el3.data.content?.icon" :src="getIconImageUrl(el3.data.id)" alt="">
                                            <div class="button-wrapper">
                                                <h5>{{ el3.data.name }}</h5>
                                            </div>
                                        </router-link>
                                    </template>
                                </div>
                                <div v-if="pinned && pinned.length > 0" class="column col-12">
                                    <h3 class="text-center">Documentos importantes</h3>
                                    <div class="grid">
                                        <div v-for="(pd, pi) in pinned" :key="pi" class="column col-12 md:col-6 lg:col-4">
                                            <a v-if="!isPdf(pd.mime_type) || (isPdf(pd.mime_type) && canDownload(pd.extra_data))" :href="downloadUrl(pd.id)" target="_blank"  :class="`column-button page-button items-start ${$appState.estate.extra_data.button_type}`">
                                                <iconify-icon :icon="documentService.getDocumentIcon(pd.mime_type)" class="mr-2" style="font-size: 1.8em;"></iconify-icon>
                                                <div class="button-wrapper">
                                                    <h5>{{ pd.title }}</h5>
                                                </div>
                                            </a>
                                            <a v-if="isPdf(pd.mime_type) && !canDownload(pd.extra_data)" :href="viewPdfUrl(pd.id)" target="_blank"  :class="`column-button page-button items-start ${$appState.estate.extra_data.button_type}`">
                                                <iconify-icon :icon="documentService.getDocumentIcon(pd.mime_type)" class="mr-2" style="font-size: 1.8em;"></iconify-icon>
                                                <div class="button-wrapper">
                                                    <h5>{{ pd.title }}</h5>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="el1.type == 'category-feed'" :class="el1.class">
                                <template v-if="el1.loading">
                                    <div class="col-6 md:col-3" v-for="n in 8" :key="n">
                                        <Skeleton shape="rectangle" width="100%" height="15px" />
                                    </div>
                                </template>
                                <ul v-if="!el1.loading">
                                    <li v-for="(d, i5) in el1.documents" :key="i5" class="mb-3">
                                        <a v-if="!isPdf(d.mime_type) || (isPdf(d.mime_type) && canDownload(d.extra_data))" :href="downloadUrl(d.id)" target="_blank" class="block surface-card shadow-2 border-round p-3">
                                            <div class="flex flex-row font-bold mb-2">
                                                <iconify-icon :icon="documentService.getDocumentIcon(d.mime_type)" class="mr-2" style="font-size: 1.8em;"></iconify-icon>
                                                <span>{{ d.title }}</span>
                                            </div>
                                            <p v-if="d.description" class="text-900">{{ d.description }}</p>
                                            <div class="text-500 text-sm text-right">{{formatDate(d.created_at)}}</div>
                                        </a>
                                        <a v-if="isPdf(d.mime_type) && !canDownload(d.extra_data)" :href="viewPdfUrl(d.id)" target="_blank" class="block surface-card shadow-2 border-round p-3">
                                            <div class="flex flex-row font-bold mb-2">
                                                <iconify-icon :icon="documentService.getDocumentIcon(d.mime_type)" class="mr-2" style="font-size: 1.8em;"></iconify-icon>
                                                <span>{{ d.title }}</span>
                                            </div>
                                            <p v-if="d.description" class="text-900">{{ d.description }}</p>
                                            <div class="text-500 text-sm text-right">{{formatDate(d.created_at)}}</div>
                                        </a>
                                    </li>
                                    <li v-if="el1.documents.length < 1" class="font-bold"><i class="pi pi-angle-right mr-2"></i>Sin resultados</li>
                                </ul>
                                <router-link class="font-bold" :to="`/categories/${el1.data.slug}`">
                                    <img v-if="el1.data.extra_data?.icon_type === 'icon' && el1.data.extra_data?.icon" :src="`https://api.iconify.design/${iconUri(el1.data.extra_data?.icon, 0)}/${iconUri(el1.data.extra_data?.icon, 1)}.svg?color=%23${el1.data.extra_data.icon_color ?? '6366F1'}`" alt="">
                                    <img v-if="el1.data.extra_data?.icon_type === 'image' && el1.data.extra_data?.icon" :src="getIconImageUrl(el1.data.id)" alt="">
                                    Ver todos
                                </router-link>
                            </div>
                            <div v-else-if="el1.type == 'category-feed-2'" :class="el1.class">
                                <template v-if="el1.loading">
                                    <div class="col-6 md:col-3" v-for="n in 8" :key="n">
                                        <Skeleton shape="rectangle" width="100%" height="15px" />
                                    </div>
                                </template>
                                <ul v-if="!el1.loading">
                                    <li v-for="(d, i5) in el1.documents" :key="i5" class="mb-3">
                                        <a v-if="!isPdf(d.mime_type) || (isPdf(d.mime_type) && canDownload(d.extra_data))" :href="downloadUrl(d.id)" target="_blank" class="block surface-card shadow-2 border-round p-3">
                                            <div class="flex flex-row font-bold mb-2">
                                                <iconify-icon :icon="documentService.getDocumentIcon(d.mime_type)" class="mr-2" style="font-size: 1.8em;"></iconify-icon>
                                                <span>{{ d.title }}</span>
                                            </div>
                                            <p v-if="d.description" class="text-900">{{ d.description }}</p>
                                        </a>
                                        <a v-if="isPdf(d.mime_type) && !canDownload(d.extra_data)" :href="viewPdfUrl(d.id)" target="_blank" class="block surface-card shadow-2 border-round p-3">
                                            <div class="flex flex-row font-bold mb-2">
                                                <iconify-icon :icon="documentService.getDocumentIcon(d.mime_type)" class="mr-2" style="font-size: 1.8em;"></iconify-icon>
                                                <span>{{ d.title }}</span>
                                            </div>
                                            <p v-if="d.description" class="text-900">{{ d.description }}</p>
                                        </a>
                                    </li>
                                    <li v-if="el1.documents.length < 1" class="font-bold"><i class="pi pi-angle-right mr-2"></i>Sin resultados</li>
                                </ul>
                                <router-link class="font-bold" :to="`/categories/${el1.data.slug}`">
                                    <img v-if="el1.data.extra_data?.icon_type === 'icon' && el1.data.extra_data?.icon" :src="`https://api.iconify.design/${iconUri(el1.data.extra_data?.icon, 0)}/${iconUri(el1.data.extra_data?.icon, 1)}.svg?color=%23${el1.data.extra_data.icon_color ?? '6366F1'}`" alt="">
                                    <img v-if="el1.data.extra_data?.icon_type === 'image' && el1.data.extra_data?.icon" :src="getIconImageUrl(el1.data.id)" alt="">
                                    Ver todos
                                </router-link>
                            </div>
                        </template>
                    </div>
                </template>
            </div>
        </template>
    </div>
    <!--<Button class="p-button-rounded fixed" style="bottom: 24px; right: 24px;">
        <router-link to="/pages/home/edit">
            <iconify-icon icon="mdi:pencil" style="color: #fff; font-size: 1.8em;" />
        </router-link>
    </Button>-->
</template>

<script>
import RouteGuardService from '@/service/RouteGuardService.js';
import EstateService from '@/service/EstateService.js';
import ApiService from '@/service/ApiService.js';
import PageService from '@/service/PageService.js';
import DocumentService from '@/service/DocumentService.js';
import ErrorReportService from '@/service/ErrorReportService.js';

export default {
    data() {
        return {
            homePage: null,
            pinned: null,
            loading: true,
        }
    },
    estateService: null,
    pageService: null,
    documentService: null,
    errorReportService: null,
    pageContent: null,
    emits: ['updateBreadcrumbs'],
    created() {
        this.estateService = new EstateService();
        this.pageService = new PageService();
        this.documentService = new DocumentService();
        this.errorReportService = new ErrorReportService();
    },
    mounted() {
        this.$emit('updateBreadcrumbs', {to: '/', replace: true});
        this.pageContent = document.querySelector('.page-content');

        if (!this.$appState.estate) {
            this.$watch(
                () => this.$appState.estate,
                () => {
                    this.fetchHomePage();
                },
            );
        } else {
            this.fetchHomePage();
        }
    },
    methods: {
        formatDate(value) {
            return value.toLocaleDateString('es-ES', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
            });
        },
        fetchHomePage() {
            this.loading = true;

            if (!this.$appState.homePage || this.$appState.refreshHomePage) {
                this.estateService.fetchEstateHomePage(this.$appState.estate.id).then(response => {
                    if (response.data.page.content.layout[0].children && response.data.page.content.layout[0].children.length > 0) {
                        this.initContent(response.data.page.content.layout[0], response.data.page.page_links, response.data.page.category_links);
                    }

                    this.homePage = this.$appState.homePage = response.data.page;
                    this.pinned = this.$appState.pinned = response.data.pinned;

                    /*if (this.homePage.content.page_type === 'basic') {
                        this.pageService.insertBasicContent(this.homePage.content.layout[0].content, this.pageContent);
                    } else if (this.homePage.content.page_type === 'custom') {
                        this.pageService.createLayout(this.homePage.content.layout, this.pageContent, this.$appState.estate.id, response.data.page.page_links, response.data.page.category_links, this.$appState.estate.extra_data.button_type);
                    }*/

                    this.loading = false;
                }).catch(error => {
                    console.error(error);
                    this.errorReportService.sendReport(
                        this.$appState.visitorId,
                        window.navigator.userAgent,
                        this.$appState.estate.id,
                        this.errorReportService.getRequestData(error.request),
                        this.errorReportService.getErrorData(error.response),
                    );
                    this.loading = false;
                    this.$toast.add({severity:'error', summary: 'Error', detail: 'No se ha podido obtener la página principal', life: 3000});

                    if (error.response && error.response.status === 401) {
                        RouteGuardService.logout();
                        window.sessionStorage.setItem('afterLogin', '/');
                        this.$router.replace(RouteGuardService.loginUrl);
                    }
                });
            } else {
                this.homePage = this.$appState.homePage;
                this.pinned = this.$appState.pinned;

                this.initContent(this.homePage.content.layout[0]);
                
                /*if (this.homePage.content.page_type === 'basic') {
                    this.pageService.insertBasicContent(this.homePage.content.layout[0].content, this.pageContent);
                } else if (this.homePage.content.page_type === 'custom') {
                    this.pageService.createLayout(this.homePage.content.layout, this.pageContent, this.$appState.estate.id, this.homePage.page_links, this.homePage.category_links, this.$appState.estate.extra_data.button_type);
                }*/

                this.loading = false;
            }
        },
        initContent(layout, pages, categories) {
            layout.children.forEach(c => {
                if (c.type && c.type === 'page-button' && !c.data) {
                    c.data = pages.find(p => p.id === c.pageId);
                } else if (c.type && c.type === 'category-button' && !c.data) {
                    c.data = categories.find(ca => ca.id === c.categoryId);
                } else if (c.type && c.type === 'category-feed' && !c.data) {
                    c.data = categories.find(ca => ca.id === c.categoryId);
                    c.loading = true;
                    c.documents = [];

                    this.pageService.fetchCategoryFeed(this.$appState.estate.id, c.categoryId, 'id', -1)
                        .then(response => {
                            c.documents = response.data.documents;
                            c.documents.forEach(document => document.created_at = new Date(document.created_at));
                            c.loading = false;
                            this.$forceUpdate();
                        })
                        .catch(error => {
                            console.error(error);
                            this.errorReportService.sendReport(
                                this.$appState.visitorId,
                                window.navigator.userAgent,
                                this.$appState.estate.id,
                                this.errorReportService.getRequestData(error.request),
                                this.errorReportService.getErrorData(error.response),
                            );
                            c.loading = false;
                            this.$forceUpdate();
                        });
                } else if (c.type && c.type === 'category-feed-2' && !c.data) {
                    c.data = categories.find(ca => ca.id === c.categoryId);
                    c.loading = true;
                    c.documents = [];

                    this.pageService.fetchCategoryFeed(this.$appState.estate.id, c.categoryId, 'title', -1)
                        .then(response => {
                            c.documents = response.data.documents;
                            c.documents.forEach(document => document.created_at = new Date(document.created_at));
                            c.loading = false;
                            this.$forceUpdate();
                        })
                        .catch(error => {
                            console.error(error);
                            this.errorReportService.sendReport(
                                this.$appState.visitorId,
                                window.navigator.userAgent,
                                this.$appState.estate.id,
                                this.errorReportService.getRequestData(error.request),
                                this.errorReportService.getErrorData(error.response),
                            );
                            c.loading = false;
                            this.$forceUpdate();
                        });
                }

                if (c.children && c.children.length > 0) {
                    this.initContent(c, pages, categories);
                }
            });
        },
        iconUri(icon, index) {
            return icon.split(':')[index];
        },
        getIconImageUrl(id) {
            const token = window.sessionStorage.getItem('token');
            return `${ApiService.getHost()}/categories/${id}/icon-image?_token=${token}`;
        },
        downloadUrl(id) {
            const token = window.sessionStorage.getItem('token');
            return `${ApiService.getHost()}/documents/${id}/download?_token=${token}`;
        },
        viewPdfUrl(id) {
            const token = window.sessionStorage.getItem('token');
            return `${ApiService.getHost()}/documents/${id}/view-pdf?_token=${token}`;
        },
        isPdf(mime_type) {
            return mime_type === 'application/pdf';
        },
        canDownload(documentExtraData) {
            if (documentExtraData && documentExtraData.disallow_download) {
                return false;
            }

            return true;
        },
    }
}
</script>
<style scoped>
</style>